export const isDev = process.env.NODE_ENV === 'development';
export const isProd = process.env.NODE_ENV === 'production';

export const siteUrl = isDev
  ? 'http://localhost:8000'
  : process.env.GATSBY_SITE_URL || 'https://earth.fm';

export const wpGraphqlUrl = isDev
  ? 'http://headlesswp.earthfm.local/graphql'
  : process.env.GATSBY_WPGRAPHQL_URL || 'https://wp.earth.fm/graphql';

export const productionSiteUrl = 'https://earth.fm';

export const frontendHost = new URL(siteUrl).host;
export const wpHost = new URL(wpGraphqlUrl).host;
export const wpUrl = wpGraphqlUrl.replace('/graphql', '');

export const isLiveEnv = isProd && frontendHost === 'earth.fm';

export const algoliaIndexName = process.env.GATSBY_ALGOLIA_INDEX_NAME || 'earth_fm';

export const pages = {
  home: '/',
  search: '/search/',
  signIn: '/signin/',
  signUp: '/signup/',
  signInWithEmail: '/sign-in-with-email/',
  checkSignInEmail: '/check-sign-in-email/',
  becomeAMember: '/become-a-member/',
  memberProfileSetup: '/member-profile-setup/',
  memberLibrary: '/member-library/',
  memberProfileSettings: '/member-settings/profile/',
  memberAccountSettings: '/member-settings/account/',
  memberMembershipSettings: '/member-settings/membership/',
  memberPrivacySettings: '/member-settings/privacy/',
  createPlaylist: '/create-playlist/',
  memberPlaylistBase: '/member-playlist/',
  about: '/about-us/',
  contact: '/contact-us/',
  privacyPolicy: 'https://hub.goadopt.io/document/1e46efc0-a250-4e8a-ab46-dff0160bb4f1',
  newsletter: '/newsletter-subscription/',
  sitemap: '/sitemap/',
  blog: '/blog/',
  glossary: '/glossary/',
  playlists: '/playlists/',
  podcast: '/podcast/',
  recordings: '/recordings/',
  shop: '/shop/',
  cart: '/cart/',
  donationCompleted: '/donation-completed/',
  artists: '/artists/',
  scholarship: '/scholarship/',
  desktopAppSignIn: '/desktop-app-sign-in',
  giftCard: '/gift-card/',
  thankYou: '/thank-you/',
  app: '/app/',
  externalCookieUrl: 'https://hub.goadopt.io/document/55532be7-9424-46af-af20-905360c28f37',
  financialAndImpactReportPage: '/financial-and-impact-report/',
  grants: '/field-recording-grant/',
  quietPlacesFinder: '/the-quietest-places-in-the-worlds-loudest-cities/',
  embed: '/embed/',
  desktopApp: '/earth-fm-for-desktop/',
  soundMap: '/nature-soundmap/',
  submit: '/submit/',
};

export const customAppPageUris = [
  '/rain-sounds-app/',
  '/ocean-sounds-app/',
  '/baby-sleep-sounds-app/',
  '/sleep-sounds-app/',
  '/forest-sounds-app/',
  '/thunderstorm-sounds-app/',
];

export const apiUrls = {
  newsletterSubscription: '/api/newsletter-subscription-handler',
  appReleaseSubscription: '/api/app-release-subscription-handler',
  trendingRecordings: '/api/trending-recordings',
};

export const earthFmPlaylistOwner = {
  id: 'earth-fm',
  name: 'Earth.fm',
};

export const nonLoggedInUserTimeLimit = 20 * 60; // 20 minutes in seconds
export const loggedInNonMemberTimeLimit = 30 * 60; // 30 minutes in seconds
// Using a non-guessable key here so that a user can't easily bypass the time limit
export const totalPlayedStorageKey = 'earth.fm:audio-player:6e3e4e3e';

export const storageKeys = {
  scholarship: 'redirect_from_scholarship',
  giftCard: 'earth.fm:gift-card',
};

export const newsletterListIds = {
  newsletter: 6,
  appRelease: 19,
};

export const appStoreLinks = {
  ios: 'https://apps.apple.com/app/apple-store/id6464324601?pt=126546789&ct=web&mt=8',
  android:
    'https://play.google.com/store/apps/details?id=com.earthfm&referrer=utm_source%3Dweb26utm_campaign%3Dweb',
};
