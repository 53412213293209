/**
 * Site wide layout persisting between page changes
 */
import * as React from 'react';
import 'modern-normalize';
import './global.css';
import { PageProps } from 'gatsby';
import { ErrorBoundary } from 'react-error-boundary';
import { HelmetProvider, Helmet } from 'react-helmet-async';
import useAudioPlayer from '../AudioPlayer/useAudioPlayer';
import AudioPlayerContext from '../AudioPlayer/AudioPlayerContext';
import FirebaseProvider from '../FirebaseProvider';
import FinishSignInWithEmail from '../FinishSignInWithEmail';
import ToastProvider from '../ToastProvider';
import StandaloneAudioPlayerContext from '../AudioPlayer/StandaloneAudioPlayerContext';
import StoreProvider from '../StoreProvider';
import SocialSharingModalProvider from '../SocialSharingModalProvider';
import TimedMembershipPopup from '../TimedMembershipPopup';
import MembershipPopupProvider from '../MembershipPopupProvider';
import AnnouncementBar from '../AnnouncementBar';
import SearchContextProvider from '../PageLayout/SearchContextProvider';
import EmbedTrackContext from '../../contexts/EmbedTrackContext';
import { isLiveEnv, pages } from '../../../config';

const isBrowser = typeof window !== undefined;

const AudioPlayer = React.lazy(() => import('../AudioPlayer/AudioPlayer'));

function InnerGlobalLayout({ children, location }: PageProps) {
  const [pathname, setPathname] = React.useState<string | null>(null);
  const audioPlayer = useAudioPlayer();

  React.useEffect(() => {
    if (isBrowser) {
      setPathname(location.pathname);
    }
  }, [location.pathname]);

  const isEmbedPage = React.useMemo(
    () => location.pathname.includes(pages.embed),
    [location.pathname],
  );

  if (location.pathname.startsWith('/twitter-player/')) {
    return <div>{children}</div>;
  }

  return (
    <HelmetProvider>
      <AudioPlayerContext.Provider value={audioPlayer}>
        {isBrowser && isLiveEnv && !isEmbedPage && (
          <Helmet>
            {/* Add adopt cookie banner */}
            <meta name="adopt-website-id" content="baf6ddc7-135f-45da-949a-7700982d4a49" />
            <script
              src="//tag.goadopt.io/injector.js?website_code=baf6ddc7-135f-45da-949a-7700982d4a49"
              className="adopt-injector"
            />
          </Helmet>
        )}
        <ToastProvider>
          <SocialSharingModalProvider>
            <StoreProvider>
              <SearchContextProvider>
                <AnnouncementBar pathname={pathname} />
                {children}
              </SearchContextProvider>
            </StoreProvider>
          </SocialSharingModalProvider>
        </ToastProvider>
        <React.Suspense>
          <AudioPlayer />
        </React.Suspense>
        <FinishSignInWithEmail />
        {/* Make sure an error in TimedMembershipPopup doesn't break the app */}
        <ErrorBoundary fallback={null}>
          <TimedMembershipPopup />
        </ErrorBoundary>
      </AudioPlayerContext.Provider>
    </HelmetProvider>
  );
}

export default function GlobalLayout(pageProps: PageProps) {
  const [isStandaloneAudioPlayerPlaying, setIsStandaloneAudioPlayerPlaying] = React.useState(false);
  const [currentlyPlayingItem, setCurrentlyPlayingItem] = React.useState('');
  const [theme, setTheme] = React.useState<string>('green');
  const [size, setSize] = React.useState<number>(190);
  const currentlyPlayingItemValue = React.useMemo(
    () => ({
      isStandaloneAudioPlayerPlaying,
      setIsStandaloneAudioPlayerPlaying,
      currentlyPlayingItem,
      setCurrentlyPlayingItem,
    }),
    [
      isStandaloneAudioPlayerPlaying,
      setIsStandaloneAudioPlayerPlaying,
      currentlyPlayingItem,
      setCurrentlyPlayingItem,
    ],
  );

  const contextValue = React.useMemo(
    () => ({
      theme,
      setTheme,
      size,
      setSize,
    }),
    [size, theme],
  );
  return (
    <EmbedTrackContext.Provider value={contextValue}>
      <StandaloneAudioPlayerContext.Provider value={currentlyPlayingItemValue}>
        <FirebaseProvider>
          <MembershipPopupProvider>
            {InnerGlobalLayout !== undefined && (
              // eslint-disable-next-line react/jsx-props-no-spreading
              <InnerGlobalLayout {...pageProps} />
            )}
          </MembershipPopupProvider>
        </FirebaseProvider>
      </StandaloneAudioPlayerContext.Provider>
    </EmbedTrackContext.Provider>
  );
}
