import * as React from 'react';
import styled from 'styled-components';
import Modal from '../Modal';
import ThemeSelector from './ThemeSelector';
import { Membership, PlaybackItem } from '../../types';
import { getRecordingData } from '../../utils/frontend';
import { cssClampValue, recordingToPlaybackItem } from '../../utils/common';
import ToastContext from '../ToastProvider/ToastContext';
import EmbedTrackContext from '../../contexts/EmbedTrackContext';
import SizeSelector from './SizeSelector';
import EmbeddableAudioPlayer from '../EmbeddableAudioPlayer';
import FirebaseContext from '../../contexts/FirebaseContext';
import { productionSiteUrl } from '../../../config';

const SelectorWrapper = styled.div`
  display: flex;
  margin-bottom: ${cssClampValue(20, 30)};
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: column;

  @media (min-width: 768px) {
    flex-direction: row;
    align-items: center;
  }
`;

const PreviewWrapper = styled.div<{ $maxHeight: number }>`
  max-height: ${({ $maxHeight }) => `${$maxHeight}px`};
  margin-bottom: ${cssClampValue(20, 30)};
  border-radius: ${cssClampValue(5, 10)};
  overflow: hidden;
`;

const Textarea = styled.textarea`
  width: 100%;
  padding: 10px ${cssClampValue(10, 20)};
  color: var(--earth-fm--color--green);
  background-color: var(--earth-fm--color--beige);
  font-size: 14px;
  word-break: break-all;
  line-height: 1.5;
  border: 1px solid var(--earth-fm--color--green-15);
  border-radius: ${cssClampValue(5, 10)};
  overflow: auto;
`;

const Info = styled.p`
  margin: 0;
  padding: 0 10px;
  font-size: 14px;

  @media (min-width: 768px) {
    text-align: center;
  }
`;

type Props = {
  id: number;
  open: boolean;
  onClose: () => void;
};

export default function EmbedTrackModal({ open, onClose, id }: Props) {
  const [recording, setRecording] = React.useState<PlaybackItem | null>(null);
  const [embedCode, setEmbedCode] = React.useState<string | null>(null);
  const [showCode, setShowCode] = React.useState(false);
  const { showToast } = React.useContext(ToastContext);
  const { size, theme } = React.useContext(EmbedTrackContext);
  const { user, membership, loadingMembership, member } = React.useContext(FirebaseContext);
  const isLoggedInUser = user !== null;
  const isMember = isLoggedInUser && !loadingMembership && membership !== Membership.None;

  const generateEmbedTrackCode = React.useCallback(
    (recordingId: number, trackTheme: string, trackSize: number): string =>
      `<iframe src="${productionSiteUrl}/embed/${recordingId}?theme=${trackTheme}&size=${trackSize.toString()}" width="100%" height="${trackSize}" style="border: none;" allow="encrypted-media; clipboard-write;"></iframe>`,
    [],
  );

  React.useEffect(() => {
    // fetch recording data from the server
    async function fetchData() {
      try {
        const data = await getRecordingData(id);
        if (!data) return;

        const playbackItem = recordingToPlaybackItem(data);

        setRecording(playbackItem);
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
      }
    }
    fetchData();
  }, [id]);

  React.useEffect(() => {
    if (recording) {
      setEmbedCode(generateEmbedTrackCode(recording.id, theme, size));
    }
  }, [generateEmbedTrackCode, recording, size, theme]);

  const handleCopy = React.useCallback(() => {
    if (!embedCode) return;
    navigator.clipboard.writeText(embedCode).then(() => {
      showToast({ message: 'Copied to clipboard!' });
    });
  }, [embedCode, showToast]);

  const handleShowCode = React.useCallback(() => {
    setShowCode((prev) => !prev);
  }, []);

  if (!recording) {
    return null;
  }

  return (
    <Modal
      open={open}
      onClose={onClose}
      title="Embed Track"
      primaryButton={{
        content: 'Copy',
        onClick: handleCopy,
      }}
      secondaryButtons={[
        {
          content: `${showCode ? 'Hide' : 'Show'} Code`,
          onClick: handleShowCode,
        },
      ]}
    >
      <div>
        <SelectorWrapper>
          <ThemeSelector />
          <SizeSelector />
        </SelectorWrapper>
        {embedCode && (
          <PreviewWrapper $maxHeight={size}>
            <EmbeddableAudioPlayer
              data={recording}
              trackTheme={theme}
              trackSize={size}
              isPreview
              isMember={isMember}
              memberId={member?.id || null}
            />
          </PreviewWrapper>
        )}
        {showCode && embedCode && (
          <>
            <Textarea value={embedCode} readOnly rows={3} />
            <Info>
              For a WordPress site, try pasting the embed code into a <b>custom HTML</b> block.
            </Info>
          </>
        )}
      </div>
    </Modal>
  );
}
